import React from "react";
import { classNames } from "../utils/classNames";
import { Spinner } from "./spinner";

export type ButtonProps = {
  text: string;
  type?: "button" | "submit";
  color?: "primary" | "white" | "secondary" | "danger" | "dark" | "transparent";
  onClick?: () => any;
  loading?: boolean;
  loadingText?: string;
  Icon?: (any) => JSX.Element;
  enabled?: boolean;
  full?: boolean;
  size?: Size;
};

export const Button = ({
  Icon,
  onClick,
  loading,
  color = "primary",
  loadingText = "Loading...",
  type = "button",
  text,
  enabled = true,
  full = false,
  size = "md",
}: ButtonProps) => {
  let isPrimary = color == "primary";
  let isWhite = color == "white";
  let isDark = color == "dark";
  let isSecodary = color == "secondary";
  let isDanger = color == "danger";
  let isTransparent = color == "transparent";
  return (
    <button
      className={classNames(
        "inline-flex justify-center items-center border shadow-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60",
        isPrimary &&
          "border-transparent bg-primary-500 hover:bg-primary-600 focus:ring-primary-500",
        isWhite &&
          "bg-white text-gray-700 hover:bg-gray-50 border-gray-300 focus:ring-primary-500",
        isDark &&
          "text-white bg-gray-800 hover:bg-gray-900 focus:ring-gray-500",
        isSecodary &&
          "bg-primary-200 text-primary-800 hover:bg-primary-300 focus:ring-primary-500 border-transparent shadow-none",
        isDanger &&
          "border-transparent bg-red-500 hover:bg-red-600 focus:ring-red-400",
        isTransparent &&
          "border-transparent text-primary-600 hover:text-primary-800 shadow-none sm:p-0 border-none",
        size == "xl" ? "px-6 py-3 text-base" : "px-4 py-2 text-sm",
        full && "w-full"
      )}
      disabled={!enabled}
      {...{ type, onClick }}
    >
      {loading ? (
        <>
          <Spinner /> {loadingText}
        </>
      ) : (
        <>
          {Icon && (
            <Icon
              className={classNames(
                "-ml-1  h-5 w-5",
                isTransparent ? "mr-1" : "mr-2"
              )}
              aria-hidden="true"
            />
          )}
          {text}
        </>
      )}
    </button>
  );
};
