import { Layout } from "../components/layout";
import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { PalmaLogo } from "../components/logo";
import { Button } from "../components/button";
import { Spinner } from "../components/spinner";

const HomePage: React.FC = () => {
  const { error, isLoading } = useUser();
  const router = useRouter();

  if (isLoading)
    return (
      <div className="min-h-screen w-full flex justify-center">
        <div className="m-auto text-primary-600 font-semibold tracking-tight text-lg flex flex-col items-center">
          <PalmaLogo size="sm" />
          <Spinner />
          Loading...
        </div>
      </div>
    );
  if (error) return <div>{error.message}</div>;

  const handleLogin = async () => {
    let orgIds = JSON.parse(localStorage.getItem("org_ids"));

    if (orgIds && orgIds.length > 0) {
      let organization = orgIds[0];
      router.push({
        pathname: "/api/auth/login",
        query: { organization },
      });
    }
    router.push("/api/auth/login");
  };

  return (
    <Layout headTitle="Home">
      <div className="min-h-screen w-full sm:bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="sm:bg-white py-8 px-4 sm:shadow sm:rounded-t-xl sm:px-10 flex flex-col items-center">
            <PalmaLogo size="sm" />
            <div className="space-y-4 flex flex-col items-center py-20">
              <span className="text-2xl font-bold text-gray-900 tracking-tight">
                Sign in to your account
              </span>
              <Button
                text="Log in"
                size="xl"
                full
                onClick={handleLogin}
              />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:rounded-b-xl sm:px-6 flex flex-col items-center space-y-2">
            <span className="text-sm font-medium">
              Is your organization new to Palma?
            </span>
            <Link href="registration">
              <Button text="Create account" color="white" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
